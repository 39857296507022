<template>
  <div v-if="!preloaded" class="fixed inset-0 flex flex-col gap-6 justify-center items-center text-center text-xs">
    <p v-if="!preloadingFailed">{{ $t('we_put_together_the_available_options_for_you') }}</p>
    <LoadingIndicator v-if="!preloadingFailed" />

    <Transition name="fade">
      <div v-if="preloadingFailed" class="mt-10">
        <p v-if="errorMessage" class="text-red-600 font-bold mb-4 text-sm max-w-lg">{{ errorMessage }}</p>
        <p>{{ $t('something_went_wrong_while_retrieving_your_data') }}</p>
        <p>{{ $t('when_resetting_previously_entered_data_will_be_lost') }}</p>
        <p v-if="$flowstore.id" class="my-4">ID: {{ $flowstore.id }}</p>
        <nav class="flex flex-wrap justify-center gap-2 mt-6">
          <Button @click="reload">{{ $t('reload') }}</Button>
          <Button variant="light" @click="reinitialize">{{ $t('reset') }}</Button>
        </nav>
      </div>
    </Transition>
  </div>

  <template v-if="preloaded">
    <Header />

    <main class="container-inset min-h-[calc(100vh-8rem)] py-12 flex">
      <div class="container flex flex-1 justify-between gap-10 xl:gap-28">
        <div class="flex-grow">
          <LoadingOverlay fixed v-if="$loadingstore.validating_step || $loadingstore.validating_booking" />
          <RouterView v-slot="{ Component: RouteComponent }">
            <Transition name="page" mode="out-in" v-if="RouteComponent">
              <Component v-if="RouteComponent" :is="RouteComponent" />
            </Transition>
          </RouterView>
        </div>
        <aside class="flex-none hidden lg:block w-full max-w-md xl:max-w-lg">
          <Receipt />
        </aside>
      </div>
    </main>

    <Footer />
  </template>
</template>

<script lang="ts" setup>
  import { isAxiosError } from 'axios';
  import { onBeforeMount, ref, watch } from 'vue';
  import { removeOptionalRoutes } from '@/router';
  import { useCustomerStore } from '@/stores/useCustomerStore';
  import { useFlowStore, type CheckoutOptions } from '@/stores/useFlowStore';
  import { useNavigationStore } from '@/stores/useNavigationStore';
  import { useRoute } from 'vue-router';
  import Button from '@/components/Button.vue';
  import Footer from '@/partials/Footer.vue';
  import Header from '@/partials/Header.vue';
  import LoadingIndicator from '@/components/LoadingIndicator.vue';
  import LoadingOverlay from '@/components/LoadingOverlay.vue';
  import Receipt from '@/components/Receipt.vue';

  const flowStore = useFlowStore();
  const navStore = useNavigationStore();
  const customerStore = useCustomerStore();
  const route = useRoute();
  const preloaded = ref(false);
  const preloadingFailed = ref(false);
  const errorMessage = ref();

  // query watcher needed for CheckoutTypeCard
  watch(() => route.query, q => {
    if (q.checkout_type && q.checkout_type !== '') reinitialize();
  });

  async function doPreload() {
    errorMessage.value = null;
    preloaded.value = false;
    preloadingFailed.value = false;

    let shouldRedirectToFirstStep = false;

    /**
     * Starting the flow with a new product.
     */

    const query = new URLSearchParams(window.location.search);

    const accessToken = query.get('access_token');
    if (accessToken) {
      await customerStore.loginWithAccessToken(accessToken);
      shouldRedirectToFirstStep = true;
    }

    const qsProductId = query.get('cluster') || query.get('product'); // Set starting product. ('cluster' is used for backward compatibility)
    const startProductId = qsProductId ? parseInt(qsProductId, 10) : undefined;

    const qsBathCardId = query.get('bath_card_id');
    const startBathCardId = qsBathCardId ? parseInt(qsBathCardId, 10) : undefined;

    const qsVoucherCodes = query.get('voucher_codes') || query.get('voucher_code');
    const startVoucherCodes = qsVoucherCodes ? qsVoucherCodes.split(',') : undefined;

    const checkoutOptions: CheckoutOptions = {
      checkout: query.get('checkout') ?? undefined,
      product: startProductId,
      voucher_codes: startVoucherCodes,
      arrival_date: query.get('arrival_date') ?? undefined,
      departure_date: query.get('departure_date') ?? undefined,
      bath_card_id: startBathCardId,
      persons_count: query.get('persons_count') ?? undefined,
      checkout_type: query.get('checkout_type') ?? undefined,
      ga_origin: query.get('ga_origin') ?? 'Direct',
    };

    // When some checkout options are passed, we have to reset and start a new checkout flow.
    if (Object.values(checkoutOptions).filter(Boolean).length > 0) {
      console.warn('Starting new flow with', checkoutOptions);
      flowStore.resetFlow();
      shouldRedirectToFirstStep = true;
    }

    /**
     * Load the flow store
     */

    try {
      const presistedFlowId = flowStore.id;

      await flowStore.load(checkoutOptions);

      /**
       * Remove unused upsell routes.
       */

      // const upsellRoutes = Object.entries(flowStore.checkout.meta.upsells)
      //   .filter(([key, value]) => {
      //     if (key === 'lunch_and_diner') {
      //       return value.products.length === 0 && flowStore.checkout.meta.upsells.hotel_rooms.products.length === 0
      //     }
      //     return value.products.length === 0; // no products available in upsell step.
      //   })
      //   .map(([name]) => name);

      // removeOptionalRoutes(upsellRoutes);

      // navStore.recomputeAvailableSteps();

      /**
       * Return to the first step (reservation) when:
       *
       * - the presisted flow/id differs from the loaded flow/id.
       * - validation fails on the reservation step.
       */

      if (navStore.currentStepIndex() > 0) {
        if (flowStore.id !== presistedFlowId) {
          console.warn('New flow/id redirect to first step.');
          shouldRedirectToFirstStep = true;
        } else {
          try {
            await flowStore.validateStep('reservation');
            console.warn('Reservation looks good, continue.');
          } catch (error) {
            console.warn('Validation error in reservation step, redirect to first step.');
            shouldRedirectToFirstStep = true;
          }
        }
      }

      if (shouldRedirectToFirstStep) {
        await navStore.gotoFirstStep();
      } else {
        await navStore.gotoRememberedPath();
      }

      preloaded.value = true;
      flowStore.watchAndSaveChanges();
    } catch(error) {
      if (isAxiosError(error) && error.response && error.response.data) {
        errorMessage.value = error.response.data.message;
      } else {
        console.error(error);
      }
      setTimeout(() => {
        preloadingFailed.value = true;
      }, 2000);
    }
  }

  async function reload() {
    await doPreload();
  }

  async function reinitialize() {
    flowStore.resetFlow();
    await doPreload();
  }

  onBeforeMount(async () => {
    await doPreload();
  });
</script>
