<template>
  <Modal ref="modal">
    <template #header>
      <div class="grid grid-cols-2">
        <div v-for="checkoutType in checkoutTypes" :key="checkoutType.type" class="bg-qwr-dark-50 aspect-video">
          <img v-if="checkoutType.image" class="h-full w-full object-cover" :src="checkoutType.image" loading="lazy" />
        </div>
      </div>
    </template>

    <h1 class="text-2xl font-serif">{{ $t('what_is_the_difference') }}</h1>

    <div v-for="checkoutType in checkoutTypes" :key="checkoutType.type">
      <h2 class="font-bold my-2">{{ checkoutType.name }}</h2>
      <template v-if="checkoutType.type === 'entree-thermaalbad'">
        <ul class="list-disc list-inside">
          <li>{{ $t('compare_etb_usp_1') }}</li>
          <li>{{ $t('compare_etb_usp_2') }}</li>
          <li>{{ $t('compare_etb_usp_3') }}</li>
          <li>{{ $t('compare_etb_usp_4') }}</li>
        </ul>
        <p class="italic py-4">{{ $t('compare_etb_comment') }}</p>
      </template>
      <template v-if="checkoutType.type === 'entree-thermaalbad-plus-sauna'">
        <ul class="list-disc list-inside">
          <li>{{ $t('compare_etbs_usp_1') }}</li>
          <li>{{ $t('compare_etbs_usp_2') }}</li>
          <li>{{ $t('compare_etbs_usp_3') }}</li>
          <li>{{ $t('compare_etbs_usp_4') }}</li>
          <li>{{ $t('compare_etbs_usp_5') }}</li>
          <li>{{ $t('compare_etbs_usp_6') }}</li>
          <li>{{ $t('compare_etbs_usp_7') }}</li>
        </ul>
        <p class="italic py-4">{{ $t('compare_etbs_comment') }}</p>
      </template>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import Modal from '@/components/Modal.vue';
  import type { CheckoutTypeResource } from '@/api/resources';

  export interface Props {
    checkoutTypes: CheckoutTypeResource[]
  }

  defineProps<Props>();

  const modal = ref<InstanceType<typeof Modal>>();

  const openModal = () => {
    modal.value?.openModal();
  };

  const closeModal = () => {
    modal.value?.closeModal();
  };

  defineExpose({
    openModal,
    closeModal,
  });
</script>
