<template>
  <div ref="tooltipContainer" class="relative inline-block">
    <button type="button" @click.stop="setTooltip(!open)" @mouseleave="onHover ? setTooltip(false) : null" @mouseenter="onHover ? setTooltip(true) : null">
      <span class="block pointer-events-none">
        <slot name="toggle">
          <HelpCircle class="w-3.5" />
        </slot>
      </span>
    </button>

    <Transition name="fade">
      <div ref="tooltip" v-if="open" @click.stop class="absolute top-[calc(100%+1rem)] bg-white shadow-tooltip rounded-md p-6 w-[calc(100vw-2rem)] text-sm z-10 text-black cursor-default"
        :class="{
          'left-0': align === 'left',
          'right-0': align === 'right',
        }"
        :style="{
          left: align === 'left' ? `${tooltipOffset.x}px`: undefined,
          right: align === 'right' ? `${tooltipOffset.x}px`: undefined,
          maxWidth: `${tooltipOffset.width}px`,
        }"
      >
        <button v-if="!onHover && closeIcon" type="button" @click="open = false" class="absolute right-4 top-4">
          <Cross class="h-2.5 w-2.5" />
        </button>
        <slot />
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
  import { ref, watch, onBeforeUnmount, nextTick, onMounted } from 'vue';
  import HelpCircle from 'thermen-theme/icons/help-circle.svg?component';
  import Cross from 'thermen-theme/icons/cross.svg?component';

  export interface Props {
    onHover?: boolean
    closeIcon?: boolean
    clickAnywhereToClose?: boolean
    align?: 'left' | 'right'
  }

  const props = withDefaults(defineProps<Props>(), {
    onHover: false,
    closeIcon: true,
    clickAnywhereToClose: true,
    align: 'left',
  });

  const MAX_WIDTH = 512;
  const tooltip = ref();
  const tooltipContainer = ref();
  const tooltipOffset = ref({ x: 0, y: 0, width: MAX_WIDTH });
  const open = ref(false);

  // Prevents mouseover and click events cancelling out eachother.
  const cancellation = ref(0);

  const setTooltip = (newState: boolean) => {
    if (Date.now() - cancellation.value < 10) return;
    open.value = newState;
    cancellation.value = Date.now();
  };

  const isDescendant = (parent: Element, child: Element) => {
    let node = child.parentNode;
    while (node != null) {
      if (node == parent) return true;
      node = node.parentNode;
    }
    return false;
  };

  const globalClickEvent = (event: Event) => {
    if (open.value && !isDescendant(tooltipContainer.value, event.target as HTMLElement)) {
      open.value = false;
    }
  };

  const onResize = () => {
    open.value = false;
  };

  onBeforeUnmount(() => {
    document.removeEventListener('resize', onResize);
    if (props.clickAnywhereToClose) document.removeEventListener('click', globalClickEvent, true);
  });

  watch(open, value => {
    if (value) addEventListener('resize', onResize);
    if (!value) removeEventListener('resize', onResize);

    if (value && props.clickAnywhereToClose) document.addEventListener('click', globalClickEvent, true);
    if (!value && props.clickAnywhereToClose) document.removeEventListener('click', globalClickEvent, true);
  });

  watch(tooltip, async (element) => {
    if (!element) return;

    // Reset the translate
    tooltipOffset.value = { x: 0, y: 0, width: MAX_WIDTH };

    await nextTick(); // wait for the element to be rendered.

    const boundry = { x: 16, y: 100 };
    const position = element.getBoundingClientRect();

    const viewportWidth = Math.min(window.outerWidth, window.innerWidth);
    const width = Math.min(MAX_WIDTH, viewportWidth - (boundry.x * 2));

    switch (props.align) {
      case 'left':
        if (position.right > viewportWidth - boundry.x) {
          tooltipOffset.value = {
            x: -(position.right - viewportWidth) - boundry.x,
            y: 0,
            width,
          };
        }
        break;
      case 'right':
        if (position.left < boundry.x) {
          tooltipOffset.value = {
            x: position.left - boundry.x,
            y: 0,
            width,
          };
        }
        break;
    }

    // if (position.bottom > window.innerHeight) {
    //   tooltipOffset.value.y = -(position.bottom - window.innerHeight) - boundry.y;
    // }
  });
</script>
