<template>
  <div class="flex flex-col gap-8">
    <div>
      <h1 class="text-2xl font-serif">{{ $flowstore.checkout.meta.text.customer.title }}</h1>
      <p>{{ $flowstore.checkout.meta.text.customer.description }}</p>
    </div>

    <ForgotPasswordModal ref="forgotPasswordModal" />

    <div>
      <h2 class="text-xl font-serif mb-4">{{ $t('how_would_you_like_to_book_your_relaxing_moment') }}</h2>
      <div class="flex flex-col gap-4 mb-10">
        <RadioField v-model="$customerstore.authentication.action" group="action" onValue="login" :errors="$validationstore.errors['authentication.action']">
          {{ $t('i_have_a_my_thermen_account') }}
        </RadioField>
        <RadioField v-model="$customerstore.authentication.action" group="action" onValue="register" :errors="$validationstore.errors['authentication.action']">
          {{ $t('i_want_to_create_a_my_thermen_account') }}
          <Tooltip onHover>{{ $t('i_want_to_create_a_my_thermen_account_tooltip') }}</Tooltip>
        </RadioField>
        <RadioField v-model="$customerstore.authentication.action" group="action" onValue="guest" :errors="$validationstore.errors['authentication.action']">
          {{ $t('i_want_to_make_a_reservation_without_an_account') }}
        </RadioField>
      </div>

      <div v-if="$customerstore.authentication.action === 'register'" class="grid grid-cols-12 gap-6 mt-10">
        <PasswordField name="password" autocomplete="new-password" v-model="$customerstore.authentication.password" :errors="$validationstore.errors['authentication.password']" class="col-span-full lg:col-span-6">
          {{ $t('password') }}
        </PasswordField>
        <PasswordField name="password_confirmation" autocomplete="new-password" v-model="$customerstore.authentication.password_confirmation" :errors="$validationstore.errors['authentication.password_confirmation']" class="col-span-full lg:col-span-6">
          {{ $t('password_confirmation') }}
        </PasswordField>
      </div>

      <form v-if="$customerstore.authentication.action === 'login'" method="post" @submit.prevent="loginCustomer" class="my-10">
        <LoadingOverlay v-if="$loadingstore.login" />

        <div v-if="!isAuthenticated" class="grid grid-cols-12 gap-6">
          <TextField name="username" v-model="$customerstore.authentication.email" :errors="$validationstore.errors['email']" class="col-span-full sm:col-span-6">
            {{ $t('email_address') }}
          </TextField>
          <PasswordField name="password" v-model="$customerstore.authentication.password" :errors="$validationstore.errors['password']" class="col-span-full sm:col-span-6">
            {{ $t('password') }}
          </PasswordField>
        </div>

        <div class="mt-4">
          <div class="text-sm text-qwr-dark-600" v-if="!isAuthenticated && $validationstore.errors['authentication']">
            {{ $t('forgot_your_password_no_problem') }}
            <button type="button" class="text-sm underline text-primary text-left" @click="forgotPasswordModal?.openModal()">
              {{ $t('request_your_new_password_here') }}
            </button>
          </div>
          <ErrorBag isNotice :errors="$validationstore.errors['forgot_password']" />
        </div>

        <div class="flex justify-between mt-6">
          <Button v-if="!isAuthenticated" type="submit" @click="loginCustomer">{{ $t('login') }}</Button>
          <Button v-if="isAuthenticated" @click="logoutCustomer">{{ $t('logout') }}</Button>
          <ErrorBag :errors="$validationstore.errors['authentication']" />
        </div>
      </form>
    </div>

    <div class="grid grid-cols-12 gap-6">
      <RadioField group="gender" v-model="$customerstore.personal.gender" onValue="male" :errors="$validationstore.errors['personal_data.gender']" class="col-span-4">
        {{ $t('mr') }}
      </RadioField>
      <RadioField group="gender" v-model="$customerstore.personal.gender" onValue="female" :errors="$validationstore.errors['personal_data.gender']" class="col-span-4">
        {{ $t('ms') }}
      </RadioField>
      <RadioField group="gender" v-model="$customerstore.personal.gender" onValue="other" :errors="$validationstore.errors['personal_data.gender']" class="col-span-4">
        {{ $t('other') }}
      </RadioField>

      <ErrorBag :errors="$validationstore.errors['personal_data.gender']" class="col-span-full -mt-5" />

      <template v-if="!$customerstore.personal.address.country || $customerstore.personal.address.country.toUpperCase() === 'NL'">
        <TextField name="first_name" v-model="$customerstore.personal.first_name" placeholder="given-name" :errors="$validationstore.errors['personal_data.first_name']" class="col-span-full lg:col-span-5">
          {{ $t('first_name') }}
        </TextField>
        <TextField name="infix" v-model="$customerstore.personal.infix" class="col-span-4 lg:col-span-3" placeholder="additional-name">
          {{ $t('name_infix') }}
        </TextField>
        <TextField name="last_name" v-model="$customerstore.personal.last_name" autocomplete="family-name" :errors="$validationstore.errors['personal_data.last_name']" class="col-span-8 lg:col-span-4">
          {{ $t('last_name') }}
        </TextField>
        <TextField name="email" v-model="$customerstore.personal.email" :disabled="isAuthenticated" autocomplete="email" :errors="$validationstore.errors['personal_data.email']" class="col-span-full lg:col-span-6">
          {{ $t('email_address') }}
        </TextField>
        <TextField name="phone_number" v-model="$customerstore.personal.phone_number" :errors="$validationstore.errors['personal_data.phone_number']" class="col-span-full lg:col-span-6">
          {{ $t('mobile_phone_number') }}
        </TextField>
        <TextField name="zip_code" v-model="$customerstore.personal.address.zip_code" autocomplete="postal-code" :errors="$validationstore.errors['personal_data.address.zip_code']" class="col-span-4 lg:col-span-3">
          {{ $t('zip_code') }}
        </TextField>
        <NumberField name="house_number" v-model="$customerstore.personal.address.house_number" :errors="$validationstore.errors['personal_data.address.house_number']" class="col-span-4 lg:col-span-3">
          {{ $t('house_number') }}
        </NumberField>
        <TextField name="house_number_addition" v-model="$customerstore.personal.address.house_number_addition" class="col-span-4 lg:col-span-2">
          {{ $t('house_number_addition') }}
        </TextField>
        <SelectField name="country" v-model="$customerstore.personal.address.country" autocomplete="country" :errors="$validationstore.errors['personal_data.address.country']" :options="countries" class="col-span-full lg:col-span-4">
          {{ $t('country') }}
        </SelectField>
        <TextField name="street" v-model="$customerstore.personal.address.street" autocomplete="address-line1" :errors="$validationstore.errors['personal_data.address.street']" class="col-span-full lg:col-span-6">
          {{ $t('street') }}
        </TextField>
        <TextField name="city" v-model="$customerstore.personal.address.city" autocomplete="address-level2" :errors="$validationstore.errors['personal_data.address.city']" class="col-span-full lg:col-span-6">
          {{ $t('city') }}
        </TextField>
      </template>

      <template v-else-if="$customerstore.personal.address.country.toUpperCase() === 'DE'">
        <TextField name="first_name" v-model="$customerstore.personal.first_name" placeholder="given-name" :errors="$validationstore.errors['personal_data.first_name']" class="col-span-full lg:col-span-6">
          {{ $t('first_name') }}
        </TextField>
        <TextField name="last_name" v-model="$customerstore.personal.last_name" autocomplete="family-name" :errors="$validationstore.errors['personal_data.last_name']" class="col-span-full lg:col-span-6">
          {{ $t('last_name') }}
        </TextField>
        <TextField name="email" v-model="$customerstore.personal.email" autocomplete="email" :errors="$validationstore.errors['personal_data.email']" class="col-span-full lg:col-span-6">
          {{ $t('email_address') }}
        </TextField>
        <TextField name="phone_number" v-model="$customerstore.personal.phone_number" :errors="$validationstore.errors['personal_data.phone_number']" class="col-span-full lg:col-span-6">
          {{ $t('mobile_phone_number') }}
        </TextField>
        <TextField name="street" v-model="$customerstore.personal.address.street" :errors="$validationstore.errors['personal_data.address.street']" class="col-span-full lg:col-span-6">
          {{ $t('street') }}
        </TextField>
        <NumberField name="house_number" v-model="$customerstore.personal.address.house_number" :errors="$validationstore.errors['personal_data.address.house_number']" class="col-span-4 lg:col-span-3">
          {{ $t('house_number') }}
        </NumberField>
        <TextField name="house_number_addition" v-model="$customerstore.personal.address.house_number_addition" class="col-span-4 lg:col-span-3">
          {{ $t('house_number_addition') }}
        </TextField>
        <TextField name="zip_code" v-model="$customerstore.personal.address.zip_code" autocomplete="postal-code" :errors="$validationstore.errors['personal_data.address.zip_code']" class="col-span-4 lg:col-span-6">
          {{ $t('zip_code') }}
        </TextField>
        <TextField name="city" v-model="$customerstore.personal.address.city" :errors="$validationstore.errors['personal_data.address.city']" class="col-span-full lg:col-span-3" autocomplete="address-level2">
          {{ $t('city') }}
        </TextField>
        <SelectField name="country" v-model="$customerstore.personal.address.country" autocomplete="country" :errors="$validationstore.errors['personal_data.address.country']" :options="countries" class="col-span-full lg:col-span-3">
          {{ $t('country') }}
        </SelectField>
      </template>

      <template v-else-if="!['NL', 'DE'].includes($customerstore.personal.address.country.toUpperCase())">
        <TextField name="first_name" v-model="$customerstore.personal.first_name" placeholder="given-name" :errors="$validationstore.errors['personal_data.first_name']" class="col-span-full lg:col-span-5">
          {{ $t('first_name') }}
        </TextField>
        <TextField name="infix" v-model="$customerstore.personal.infix" class="col-span-4 lg:col-span-3" autocomplete="additional-name">
          {{ $t('name_infix') }}
        </TextField>
        <TextField name="last_name" v-model="$customerstore.personal.last_name" autocomplete="family-name" :errors="$validationstore.errors['personal_data.last_name']" class="col-span-8 lg:col-span-4">
          {{ $t('last_name') }}
        </TextField>
        <TextField name="email" v-model="$customerstore.personal.email" autocomplete="email" :errors="$validationstore.errors['personal_data.email']" class="col-span-full lg:col-span-6">
          {{ $t('email_address') }}
        </TextField>
        <TextField name="phone_number" v-model="$customerstore.personal.phone_number" :errors="$validationstore.errors['personal_data.phone_number']" class="col-span-full lg:col-span-6">
          {{ $t('mobile_phone_number') }}
        </TextField>
        <TextField name="zip_code" v-model="$customerstore.personal.address.zip_code" autocomplete="postal-code" :errors="$validationstore.errors['personal_data.address.zip_code']" class="col-span-4 lg:col-span-3">
          {{ $t('zip_code') }}
        </TextField>
        <NumberField name="house_number" v-model="$customerstore.personal.address.house_number" :errors="$validationstore.errors['personal_data.address.house_number']" class="col-span-4 lg:col-span-3">
          {{ $t('house_number') }}
        </NumberField>
        <TextField name="house_number_addition" v-model="$customerstore.personal.address.house_number_addition" class="col-span-4 lg:col-span-2">
          {{ $t('house_number_addition') }}
        </TextField>
        <SelectField name="country" v-model="$customerstore.personal.address.country" autocomplete="country" :errors="$validationstore.errors['personal_data.address.country']" :options="countries" class="col-span-full lg:col-span-4">
          {{ $t('country') }}
        </SelectField>
      </template>
    </div>

    <Collapsible fonticon="gift" :title="$t('receive_a_present_on_your_birthday')" :opened="hasBirthDate">
      <h4 class="font-semibold">{{ $t('date_of_birth') }}:</h4>
      <div class="grid lg:grid-cols-8 gap-6">
        <SelectField v-model="birth_date_day" class="lg:col-span-2" variant="outlined" :options="days" />
        <SelectField v-model="birth_date_month" class="lg:col-span-3" variant="outlined" :options="months" />
        <SelectField v-model="birth_date_year" class="lg:col-span-3" variant="outlined" :options="years" />
      </div>
    </Collapsible>

    <Collapsible fonticon="mail" :title="commentFieldTitle" :opened="commentFieldDefaultOpen" @open="focusCommentField">
      <TextareaField ref="commentField" v-model="$customerstore.comment" />
    </Collapsible>

    <CheckField v-if="$flowstore.checkout.meta.product.is_workshop" v-model="$customerstore.personal.send_invoice">
      {{ $t('send_invoice') }}
    </CheckField>

    <template v-if="$flowstore.checkout.meta.product.is_workshop && $customerstore.personal.send_invoice">
      <h1 class="text-2xl font-serif">{{ $t('invoice_information') }}</h1>

      <div class="grid grid-cols-12 gap-6">
        <TextField name="business_name" v-model="$customerstore.personal.invoice.business_name" :errors="$validationstore.errors['personal_data.invoice.business_name']" class="col-span-full">
          {{ $t('business_name') }}
        </TextField>
        <TextField name="first_name" v-model="$customerstore.personal.invoice.first_name" :errors="$validationstore.errors['personal_data.invoice.first_name']" class="col-span-full lg:col-span-5">
          {{ $t('first_name') }}
        </TextField>
        <TextField name="infix" v-model="$customerstore.personal.invoice.infix" class="col-span-4 lg:col-span-3">
          {{ $t('name_infix') }}
        </TextField>
        <TextField name="last_name" v-model="$customerstore.personal.invoice.last_name" :errors="$validationstore.errors['personal_data.invoice.last_name']" class="col-span-8 lg:col-span-4">
          {{ $t('last_name') }}
        </TextField>
        <TextField name="email" v-model="$customerstore.personal.invoice.email" :errors="$validationstore.errors['personal_data.invoice.email']" class="col-span-full lg:col-span-6">
          {{ $t('email_address') }}
        </TextField>
        <TextField name="phone_number" v-model="$customerstore.personal.invoice.phone_number" :errors="$validationstore.errors['personal_data.invoice.phone_number']" class="col-span-full lg:col-span-6">
          {{ $t('mobile_phone_number') }}
        </TextField>
        <TextField name="zip_code" v-model="$customerstore.personal.invoice.address.zip_code" :errors="$validationstore.errors['personal_data.invoice.address.zip_code']" class="col-span-4 lg:col-span-3">
          {{ $t('zip_code') }}
        </TextField>
        <NumberField name="house_number" v-model="$customerstore.personal.invoice.address.house_number" :errors="$validationstore.errors['personal_data.invoice.address.house_number']" class="col-span-4 lg:col-span-3">
          {{ $t('house_number') }}
        </NumberField>
        <TextField name="house_number_addition" v-model="$customerstore.personal.invoice.address.house_number_addition" class="col-span-4 lg:col-span-2">
          {{ $t('house_number_addition') }}
        </TextField>
        <SelectField name="country" v-model="$customerstore.personal.invoice.address.country" :errors="$validationstore.errors['personal_data.invoice.address.country']" :options="countries" class="col-span-full lg:col-span-4">
          {{ $t('country') }}
        </SelectField>
        <TextField name="street" v-model="$customerstore.personal.invoice.address.street" :errors="$validationstore.errors['personal_data.invoice.address.street']" class="col-span-full lg:col-span-6">
          {{ $t('street') }}
        </TextField>
        <TextField name="city" v-model="$customerstore.personal.invoice.address.city" :errors="$validationstore.errors['personal_data.invoice.address.city']" class="col-span-full lg:col-span-6">
          {{ $t('city') }}
        </TextField>

        <div class="col-span-full">
          <TextareaField v-model="$customerstore.personal.invoice.comment" :placeholder="$t('invoice_comment_placeholder')" />
        </div>
      </div>
    </template>

    <CheckField v-model="$customerstore.terms" :errors="$validationstore.errors['terms']">
      <div>
        <p class="mb-1">{{ $t('accept_terms_sentence') }}</p>
        <ul class="list-disc pl-4 space-y-0.5">
          <li>{{ $t('terms_item_1') }}</li>
          <i18n-t tag="li" keypath="terms_item_2">
            <template #house_rules>
              <a :href="$flowstore.checkout.meta.links?.house_rules" target="_blank" class="text-primary hover:underline focus:outline-none focus:underline">
                {{ $t('house_rules') }}
              </a>
            </template>
          </i18n-t>
          <i18n-t tag="li" keypath="terms_item_3">
            <template #terms_and_conditions>
              <a :href="$flowstore.checkout.meta.links?.terms_and_conditions" target="_blank" class="text-primary hover:underline focus:outline-none focus:underline">
                {{ $t('terms_and_conditions') }}
              </a>
            </template>
            <template #cancellation_policy>
              <a :href="$flowstore.checkout.meta.links?.cancellation_policy" target="_blank" class="text-primary hover:underline focus:outline-none focus:underline">
                {{ $t('cancellation_policy') }}
              </a>
            </template>
            <template #privacy_policy>
              <a :href="$flowstore.checkout.meta.links?.privacy_policy" target="_blank" class="text-primary hover:underline focus:outline-none focus:underline">
                {{ $t('privacy_policy') }}
              </a>
            </template>
          </i18n-t>
        </ul>
      </div>
    </CheckField>

    <CheckField v-model="$customerstore.personal.opt_in">
      {{ $t('accept_mailinglist_sentence', { resort: thermenSites[config.theme] }) }}
    </CheckField>

    <CheckField v-if="$flowstore.checkout.meta.product.is_workshop" v-model="$customerstore.personal.invoice.opt_in">
      {{ $t('accept_retreats_mailinglist_sentence') }}
    </CheckField>

    <ErrorBag :errors="validationStore.errorsExceptFlat(catchAllErrorsExcept)" />

    <!-- <CheckField v-if="$customerstore.personal.address.country?.toUpperCase() === 'NL'" v-model="$customerstore.personal.thermen_fans">
      <i18n-t tag="div" keypath="accept_thermenfans_sentence">
        <template #link>
          <a href="#" target="_blank" class="text-primary hover:underline focus:outline-none focus:underline">{{ $t('thermenfans_program') }}</a>
        </template>
      </i18n-t>
    </CheckField> -->
  </div>
</template>

<script lang="ts" setup>
import { toNumber } from '@/stores/utils';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { config, dateLocale } from '@/config';
import { format, isValid, parse } from 'date-fns';
import { lookupAddress } from '@/api/endpoints/checkout';
import { storeToRefs } from 'pinia';
import { useCustomerStore } from '@/stores/useCustomerStore';
import { useFlowStore } from '@/stores/useFlowStore';
import { useI18n } from 'vue-i18n';
import { useValidationStore } from '@/stores/useValidationStore';
import Button from '@/components/Button.vue';
import CheckField from '@/components/fields/CheckField.vue';
import Collapsible from '@/components/Collapsible.vue';
import ErrorBag from '@/components/ErrorBag.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import NumberField from '@/components/fields/NumberField.vue';
import PasswordField from '@/components/fields/PasswordField.vue';
import RadioField from '@/components/fields/RadioField.vue';
import SelectField from '@/components/fields/SelectField.vue';
import TextareaField from '@/components/fields/TextareaField.vue';
import TextField from '@/components/fields/TextField.vue';
import Tooltip from '@/components/Tooltip.vue';
import ForgotPasswordModal from '@/components/modals/ForgotPasswordModal.vue';

const { t } = useI18n();
const customerStore = useCustomerStore();
const flowStore = useFlowStore();
const validationStore = useValidationStore();

const { hasBirthDate, hasComment, personal, isAuthenticated } = storeToRefs(customerStore);
const forgotPasswordModal = ref<InstanceType<typeof ForgotPasswordModal>>();

const birth_date_day = ref();
const birth_date_month = ref();
const birth_date_year = ref();
const commentField = ref<InstanceType<typeof TextareaField>>();

const catchAllErrorsExcept = [
  'authentication.action',
  'authentication.password_confirmation',
  'authentication.password',
  'authentication',
  'email',
  'forgot_password',
  'password',
  'personal_data.address.city',
  'personal_data.address.country',
  'personal_data.address.house_number',
  'personal_data.address.street',
  'personal_data.address.zip_code',
  'personal_data.email',
  'personal_data.first_name',
  'personal_data.gender',
  'personal_data.last_name',
  'personal_data.phone_number',
  'terms',
];

const thermenSites = {
  bussloo: 'Bussloo',
  soesterberg: 'Soesterberg',
  berendonck: 'Berendonck',
  badnieuweschans: 'Bad Nieuweschans',
  maarssen: 'Maarssen',
  maastricht: 'Maastricht',
}

onMounted(() => {
  if (personal.value.birth_date) {
    const safeBirthDate = personal.value.birth_date.substring(0, 10);
    const date = parse(safeBirthDate, 'yyyy-MM-dd', new Date());
    birth_date_year.value = format(date, 'yyyy');
    birth_date_month.value = format(date, 'M');
    birth_date_day.value = format(date, 'd');
  }

  if (personal.value.address.country && !countryCodes.value.includes(personal.value.address.country)) {
    personal.value.address.country = 'OT';
  }
  if (personal.value.invoice.address.country && !countryCodes.value.includes(personal.value.invoice.address.country)) {
    personal.value.invoice.address.country = 'OT';
  }
});

watch(() => validationStore.errors, async () => {
  await nextTick();
  document.querySelector('.error-scroll-anchor')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
});

watch([birth_date_year, birth_date_month, birth_date_day], values => {
  const date = parse(values.join('-'), 'yyyy-MM-dd', new Date());
  if (isValid(date)) {
    personal.value.birth_date = format(date, 'yyyy-MM-dd');
  }
});

watch(() => [personal.value.address.zip_code, personal.value.address.house_number], async ([zipCode, houseNumber]) => {
  const isCityEmpty = (!personal.value.address.city || personal.value.address.city && personal.value.address.city.trim().length === 0);
  const isStreetEmpty = (!personal.value.address.street || personal.value.address.street && personal.value.address.street.trim().length === 0);

  if (zipCode && houseNumber && isCityEmpty && isStreetEmpty) {
    const _houseNumber = toNumber(houseNumber);
    if (!_houseNumber) return;

    const address = await lookupAddress(zipCode, _houseNumber);
    if (address) {
      personal.value.address.street = address.street;
      personal.value.address.city = address.city;
      personal.value.address.country = 'NL';
    }
  }
});

watch(() => [personal.value.invoice.address.zip_code, personal.value.invoice.address.house_number], async ([zipCode, houseNumber]) => {
  const isCityEmpty = (!personal.value.invoice.address.city || personal.value.invoice.address.city && personal.value.invoice.address.city.trim().length === 0);
  const isStreetEmpty = (!personal.value.invoice.address.street || personal.value.invoice.address.street && personal.value.invoice.address.street.trim().length === 0);

  if (zipCode && houseNumber && isCityEmpty && isStreetEmpty) {
    const _houseNumber = toNumber(houseNumber);
    if (!_houseNumber) return;

    const address = await lookupAddress(zipCode, _houseNumber);
    if (address) {
      personal.value.invoice.address.street = address.street;
      personal.value.invoice.address.city = address.city;
      personal.value.invoice.address.country = 'NL';
    }
  }
});

const loginCustomer = () => {
  customerStore.login()
};
const logoutCustomer = () => {
  customerStore.logout()
};

const focusCommentField = () => {
  commentField.value?.focus();
};

const commentFieldTitle = computed(() => {
  if (flowStore.checkout.meta.overwrite_comment_field && flowStore.checkout.meta.overwritten_comment_text) {
    return flowStore.checkout.meta.overwritten_comment_text;
  }
  return t('comments_or_extra_information');
});

const commentFieldDefaultOpen = computed(() => {
  if (hasComment.value) return true;
  if (flowStore.checkout.meta.expand_comment_field) return true;
  return false;
});

const countries = computed(() => {
  const mapped = [];
  for (const code in flowStore.checkout.meta.countries) {
    mapped.push({ label: flowStore.checkout.meta.countries[code], value: code });
  }
  return mapped;
});

const countryCodes = computed(() => countries.value.map(country => country.value));

const days = ref(Array.from({ length: 31 }, (_, day) => ({
  label: String(day + 1),
  value: String(day + 1),
})));

const months = ref(
  [...Array(12).keys()].map(index => ({
    label: dateLocale.localize?.month(index), // 'Januari' ...
    value: String(index + 1),
  }))
);

const today = new Date();
const startYear = today.getFullYear();

const years = ref(Array.from({ length: 110 }, (_, year) => ({
  label: String(startYear - year),
  value: String(startYear - year),
})));
</script>
