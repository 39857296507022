import '@/app.css';

// @ts-ignore
import { version } from '../../package.json';

// polyfills
import '@/polyfills/array.toReversed';

import { omitBy, isNil, type Dictionary } from 'lodash';
import config from '@/config';
import { receiveMessages, sendMessage } from '@/lib/comm';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import { createPinia } from 'pinia';
import { stores } from '@/stores';
import { messages, type Locales, type MessageSchema } from '@/translations';
import router from '@/router';
import App from '@/views/App.vue';
import { init as initSentry, replayIntegration } from '@sentry/vue';

console.log(`Thermen Reservation Flow (v${version})`);

const pinia = createPinia();
const app = createApp(App);

const i18n = createI18n<[MessageSchema], Locales>({
  legacy: false,
  locale: config.locale,
  warnHtmlMessage: false,
  fallbackLocale: 'en-US',
  messages,
});

if (!import.meta.env.DEV) {
  initSentry({
    app,
    dsn: 'https://8f4a919227a01837ea0487e892e91ca2@o398254.ingest.sentry.io/4506579637960704',
    environment: import.meta.env.MODE,
    integrations: [
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      'CanceledError',
      'IDBFactory.open()', // reservation-flow#118: Disable these because it's because of injected third-party software.
      "Rage Click", // reservation-flow#118: No need to explain this.. right?
      'AxiosError'
    ],
  });
}

app.use(i18n);
app.use(pinia);
app.use(stores);
app.use(router);

const container = document.getElementById('app');
if (container) {
  document.body.classList.add(`theme-${config.theme}`);
  app.mount(container);

  receiveMessages('reservationflow', {
    'reservationflow.open': (data) => {

      const x = omitBy({
        checkout: data.checkout ? data.checkout.toString() : null,
        product: data.product ? data.product.toString() : null,
        voucher_code: data.voucher_code, // @deprecated since 8-4-2024
        voucher_codes: data.voucher_codes,
        arrival_date: data.arrival_date,
        departure_date: data.departure_date,
        bath_card_id: data.bath_card_id ? data.bath_card_id.toString() : undefined,
        persons_count: data.persons_count ? data.persons_count.toString() : undefined,
        access_token: data.access_token,
        checkout_type: data.checkout_type,
        lang: data.lang,
        ga_origin: data.ga_origin,
      }, isNil) as Dictionary<string>;

      console.log('Thermen Reservation flow received: reservationflow.open', x);

      window.location.search = new URLSearchParams(x).toString();
    }
  });

  sendMessage('reservationflow', 'reservationflow.ready');
}

export {}
